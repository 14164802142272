import React from 'react'
import { hostingData } from '../../data/data'
import './hosting.css'
import Shopping from '../../shoppingCart/shopping'
import {Link} from 'react-router-dom'
const Hosting = () => {
  return (
    <>
    <Shopping path=" > Dedicated Server"/>
    <div className="hosting">
        <h1>Dedicated Server</h1>
        <p>A dedicated server provides dedicated computing resources that need to host websites or applications. Dedicated servers are highly customizable in terms of the memory, storage, and data transfer speed that offer businesses many choices for hosting and managing traffic.</p>
        <div className="hosting-cards">
            {
                hostingData.map((elem)=>{
                    return <div className="hostingcard" key={elem.price}>
                        <h3>{elem.h3}</h3>
                        <p>{elem.p}</p>
                        <h1>&#x20B9; {elem.price}<span>/mo</span></h1>
                        <ul>
                            <li><i className="fa fa-check-circle"></i>{elem.processor}: <span>{elem.l1}</span></li>
                            <li><i className="fa fa-check-circle"></i>Ram: <span>{elem.l2}</span></li>
                            <li><i className="fa fa-check-circle"></i>Storage: <span>{elem.l3}</span></li>
                            <li><i className="fa fa-check-circle"></i>Data Transfer: <span>{elem.l4}</span></li>
                            <li><i className="fa fa-check-circle"></i>Public Static IP: <span>{elem.l5}</span></li>
                            <li><i className="fa fa-check-circle"></i>OS: <span>{elem.l6}</span></li>
                        </ul>
                        <Link to="#">Order Now</Link>
                    </div>
                })
            }
        </div>
    </div>
    
    </>
  )
}

export default Hosting