import React, { useState} from 'react'
import './navbar.css'
import logo from '../images/logo.png'
import {Link} from 'react-router-dom'
const Navbar = () => {

  const [hamburger,setHamburger]=useState(false);
  const [navshow,setNavshow]=useState(false);
  const [navbar,setNavbar]=useState(false)
  const [menu,setMenu]=useState(false)
  const [menu1,setMenu1]=useState(false)
  const [menu2,setMenu2]=useState(false)
  const [menu3,setMenu3]=useState(false)

  const [menu4,setMenu4]=useState(false)



  const hamClick=()=>{
    setHamburger(!hamburger)
    setNavshow(!navshow)
  }

  const menuShow=()=>{
    setMenu(!menu)
    setMenu1(false)
    setMenu2(false)
    setMenu3(false)
    setMenu4(false)
  }
  const menuShow1=()=>{
    setMenu1(!menu1)
    setMenu(false)
    setMenu2(false)
    setMenu3(false)
    setMenu4(false)
  }
  const menuShow2=()=>{
    setMenu2(!menu2)
    setMenu(false)
    setMenu1(false)
    setMenu3(false)
    setMenu4(false)

  }
  const menuShow3=()=>{
    setMenu3(!menu3)
    setMenu(false)
    setMenu1(false)
    setMenu2(false)
    setMenu4(false)
  }
  const menuShow4=()=>{
    setMenu4(!menu4)
    setMenu(false)
    setMenu1(false)
    setMenu2(false)
    setMenu3(false)
  }

  const changeBackground=()=>{
    if(window.scrollY >=80){
      setNavbar(true)
    }
    else{
      setNavbar(false)
    }
  }
  window.addEventListener("scroll",changeBackground)

  return (
    <>
    <nav className={navbar?"navbar active":"navbar"}>
      <Link to="/">
        <img src={logo} alt="" width="300px"/>
      </Link>
      <div>
        <ul id="navbar" className={navshow?"#navbar active":"#navbar"}>
          <li><Link to="/" className='active'>Home</Link></li>
          <li onClick={menuShow}><Link to="#">Products</Link>
          <ul className={menu?"menu active":"menu"}>
            <li><Link to="/cpanel-linuxcloud">cPanel Linux Cloud</Link></li>
            <li><Link to="/windows-cloud">Windows Cloud</Link></li>
            <li><Link to="/cpanel-linux-generalpurpose">Linux Cloud: General Purpose</Link></li>
            <li><Link to="/cpanel-linux-computeoptimized">Linux Cloud: Compute Optimized</Link></li>
            <li><Link to="/cpanel-linux-memoryoptimized">Linux Cloud: Memory Optimized</Link></li>
            </ul></li>
          <li onClick={menuShow1}><Link to="#">Hosting</Link>
          <ul className={menu1?"menu active":"menu"}>
            <li><Link to="/hosting">Dedicated Server</Link></li>
            
            </ul></li>
          <li onClick={menuShow2}><Link to="#">Domain</Link>
          <ul className={menu2?"menu active":"menu"}>
            <li>Register a New Domain</li>
            <li>Transfer Domains to US</li>
            <li>Renew Domain</li>
            <li>Domain Search</li>
            </ul></li>
          <li><Link to="/sslcertificate">SSL Certificates</Link></li>
          <li onClick={menuShow3}><Link to="#">Cloud Training</Link>
          <ul className={menu3?"menu active":"menu"}>
            <li><Link to="/training">Azure</Link></li>

            </ul></li>
          <li onClick={menuShow4}><Link to="#">Account</Link>
          <ul className={menu4?"menu active":"menu"}>
            <li>Login</li>
            <li>Forgot Password</li>
            </ul></li>
        </ul>
      </div>
      <div id="mobile">
      <i id="bar" className={hamburger?"fa fa-close":"fa fa-bars"} onClick={hamClick}></i>
      </div>
    </nav>
    </>
  )
}

export default Navbar