import React from 'react'
import './shopping.css'
const shopping = (props) => {
  return (
    <>
    <div className="shopping">
        <h1>Shopping Cart {props.path}</h1>
    </div>
    <div className="head">
        <p>Shopping Cart</p>
    </div>
    <hr />
    </>
  )
}

export default shopping