import img1 from '../images/hc1.png'
import img2 from '../images/hc2.png'
import img3 from '../images/hc3.png'
import img4 from '../images/hc4.png'
import img5 from '../images/hc5.png'
import img6 from '../images/hc6.png'
import azure104 from '../images/az-104.jpg'

export const homeCards=[
    
    {imgUrl:`${img1}`,h3:"Dedicated Server",p:"A dedicated server provides dedicated computing resources that need to host websites or applications. Dedicated servers are highly customizable in terms of the memory, storage, and data transfer speed that offer businesses many choices for hosting and managing traffic.",price:"13,998",link:""},
    {imgUrl:`${img2}`,h3:"cPanel Linux Cloud",p:"The Linux-based control panel is used to conveniently manage your web hosting.cPanel Linux cloud along with 100% of SSD storage and It is designed to provide a stable, secure, and high-performance execution.",price:"3,030",link:""},
    {imgUrl:`${img3}`,h3:"Windows Cloud",p:"Run your workloads on a powerful, reliable Windows Server. It is multi-purpose with a balanced CPU to memory ratio that lets you attach a significant SSD storage as per your requirement. Windows cloud gives the flexibility of virtualization for a wide range of computing solutions. Provide secure compute for your application and optimize performance and cost.",price:"5,564",link:""},
    {imgUrl:`${img4}`,h3:"Cloud: General Purpose",p:"Low traffic web servers, applications, development, test servers, small and medium databases require a balance of compute, memory and networking resources.",price:"1,103",link:""},
    {imgUrl:`${img5}`,h3:"Cloud: Compute Optimized",p:"High performance web servers, media transcoding, machine learning inference, high performance databases require high CPU to memory ratio.",price:"4,038",link:""},
    {imgUrl:`${img6}`,h3:"Cloud: Memory Optimized",p:"High memory compute are designed for better performance of databases such as MySQL, No SQL, ERP worklaods and data warehousing solution.",price:"2,598",link:""},
]

export const hostingData=[
    {h3:"DS_Basic: 1",p:"&#x20B9; 5,000 Setup Fee",price:"13,998",l1:"1 x 4 Core",l2:"16 GB DDR3",l3:"480 GB SSD RAID 1",processor:"Processor E5 26xx v2",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Basic: 2",p:"&#x20B9; 5,000 Setup Fee",price:"14,328",l1:"1 x 6 Core",l2:"32 GB DDR3",l3:"480 GB SSD RAID 1",processor:"Processor E5 26xx v2",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Basic: 3",p:"&#x20B9; 5,000 Setup Fee",price:"15,91",l1:"2 x 4 Core",l2:"64 GB DDR3",l3:"480 GB SSD RAID 1",processor:"Processor E5 26xx v2",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Basic: 4",p:"&#x20B9; 5,000 Setup Fee",price:"17,652",l1:"2 x 6 Core",l2:"128 GB DDR3",l3:"960 GB SSD RAID 1",processor:"Processor E5 26xx v2",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Basic: 5",p:"&#x20B9; 5,000 Setup Fee",price:"18,150",l1:"2 x 8 Core",l2:"128 GB DDR3",l3:"960 GB SSD RAID 1",processor:"Processor E5 26xx v2",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Basic: 6",p:"&#x20B9; 5,000 Setup Fee",price:"18,977",l1:"2 x 10 Core",l2:"128 GB DDR3",l3:"960 GB SSD RAID 1",processor:"Processor E5 26xx v2",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Standard: 1",p:"&#x20B9; 7,000 Setup Fee",price:"20,482",l1:"2 x 6 Core",l2:"32 GB DDR4",l3:"480 GB SSD RAID 1",processor:"Processor E5 26xx v3/v4",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Standard: 2",p:"&#x20B9; 7,000 Setup Fee",price:"23,444",l1:"2 x 8 Core",l2:"64 GB DDR4",l3:"960 GB SSD RAID 1",processor:"Processor E5 26xx v3/v4",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Standard: 3",p:"&#x20B9; 7,000 Setup Fee",price:"25,241",l1:"2 x 10 Core",l2:"128 GB DDR4",l3:"960 GB SSD RAID 1",processor:"Processor E5 26xx v3/v4",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Standard: 4",p:"&#x20B9; 7,000 Setup Fee",price:"26,482",l1:"2 x 12 Core",l2:"128 GB DDR4",l3:"960 GB SSD RAID 1",processor:"Processor E5 26xx v3/v4",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Standard: 5",p:"&#x20B9; 7,000 Setup Fee",price:"33,153",l1:"2 x 14 Core",l2:"256 GB DDR4",l3:"960 GB SSD RAID 1",processor:"Processor E5 26xx v3/v4",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Standard: 6",p:"&#x20B9; 7,000 Setup Fee",price:"40,000",l1:"2 x 16 Core",l2:"256 GB DDR4",l3:"960 GB SSD RAID 1",processor:"Processor E5 26xx v3/v4",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Premium: 1",p:"&#x20B9; 10,000 Setup Fee",price:"26,096",l1:"2 x 8 Core",l2:"64 GB DDR4",l3:"960 GB SSD RAID 1",processor:"Silver Processor",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Premium: 2",p:"&#x20B9; 10,000 Setup Fee",price:"28,163",l1:"2 x 10 Core",l2:"128 GB DDR4",l3:"960 GB SSD RAID 1",processor:"Silver Processor",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Premium: 3",p:"&#x20B9; 10,000 Setup Fee",price:"30,848",l1:"2 x 12 Core",l2:"128 GB DDR4",l3:"960 GB SSD RAID 1",processor:"Silver Processor",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Premium: 4",p:"&#x20B9; 10,000 Setup Fee",price:"43,603",l1:"2 x 16 Core",l2:"256 GB DDR4",l3:"960 GB SSD RAID 1",processor:"Silver Processor",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Premium: 5",p:"&#x20B9; 10,000 Setup Fee",price:"48,051",l1:"1 x 16 Core",l2:"256 GB DDR4",l3:"960 GB SSD RAID 1",processor:"Gold Processor",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
    {h3:"DS_Premium: 6",p:"&#x20B9; 10,000 Setup Fee",price:"52,452",l1:"1 x 20 Core",l2:"512 GB DDR4",l3:"960 GB SSD RAID 1",processor:"Gold Processor",l4:"1 TB",l5:"1",l6:"CentOS/Debian/Ubuntu",url:""},
]

export const sslData=[
    {h3:"Positive SSL",p:".",price:"175",warran:"10,000",url:''},
    {h3:"Comodo SSL",p:"&#x20B9; 200 Setup Fee",price:"550",warran:"250,000",url:""},
    {h3:"Positive SSL Wildcard",p:"&#x20B9; 3,000 Setup Fee",price:"800",warran:"10,000",url:""},
]


export const cpanelData=[
    {h3:"cP1_24",price:"3,030",l1:"Single/One",l2:"2 vCPU, 4 GB RAM",l3:"80 GB SSD",l4:"100GB",link:""},
    {h3:"cP1_48",price:"3,992",l1:"Single/One",l2:"4 vCPU, 8 GB RAM",l3:"100 GB SSD",l4:"100GB",link:""},
    {h3:"cP1_416",price:"4,690",l1:"Single/One",l2:"4 vCPU, 16 GB RAM",l3:"100 GB SSD",l4:"100GB",link:""},
    {h3:"cP5_48",price:"4,766",l1:"5 Users/5 Accounts",l2:"4 vCPU, 8 GB RAM",l3:"100 GB SSD",l4:"100GB",link:""},
    {h3:"cP5_416",price:"5,464",l1:"5 Users/5 Accounts",l2:"4 vCPU, 16 GB RAM",l3:"100 GB SSD",l4:"100GB",link:""},
    {h3:"cP5_618",price:"7,632",l1:"5 Users/5 Accounts",l2:"6 vCPU, 18 GB RAM",l3:"300 GB SSD",l4:"200GB",link:""},
    {h3:"cP5_816",price:"8,851",l1:"5 Users/5 Accounts",l2:"8 vCPU, 16 GB RAM",l3:"400 GB SSD",l4:"300GB",link:""},
    {h3:"cP5_824",price:"9,548",l1:"5 Users/5 Accounts",l2:"8 vCPU, 24 GB RAM",l3:"400 GB SSD",l4:"300GB",link:""},
    {h3:"cP5_832",price:"10,246",l1:"5 Users/5 Accounts",l2:"8 vCPU, 32 GB RAM",l3:"400 GB SSD",l4:"300GB",link:""},
    {h3:"cP30_618",price:"8,418",l1:"30 Users/30 Accounts",l2:"6 vCPU, 18 GB RAM",l3:"300 GB SSD",l4:"200GB",link:""},
    {h3:"cP30_624",price:"8,941",l1:"30 Users/30 Accounts",l2:"6 vCPU, 24 GB RAM",l3:"300 GB SSD",l4:"200GB",link:""},
    {h3:"cP30_816",price:"9,617",l1:"30 Users/30 Accounts",l2:"8 vCPU, 16 GB RAM",l3:"400 GB SSD",l4:"300GB",link:""},
    {h3:"cP30_824",price:"10,334",l1:"30 Users/30 Accounts",l2:"8 vCPU, 24 GB RAM",l3:"400 GB SSD",l4:"300GB",link:""},
    {h3:"cP30_832",price:"11,032",l1:"30 Users/30 Accounts",l2:"8 vCPU, 32 GB RAM",l3:"400 GB SSD",l4:"300GB",link:""},
    {h3:"cP30_1236",price:"13,268",l1:"30 Users/30 Accounts",l2:"12 vCPU, 36 GB RAM",l3:"500 GB SSD",l4:"400GB",link:""},
    {h3:"cP30_1248",price:"14,314",l1:"30 Users/30 Accounts",l2:"12 vCPU, 48 GB RAM",l3:"500 GB SSD",l4:"400GB",link:""},
    {h3:"cP30_1264",price:"15,708",l1:"30 Users/30 Accounts",l2:"12 vCPU, 64 GB RAM",l3:"500 GB SSD",l4:"400GB",link:""},
]

export const windowCloudData=[
    {h3:"WC4_16",price:"5,564",l2:"4 vCPU, 16 GB RAM",l3:"100 GB SSD",l4:"100 GB",link:""},
    {h3:"WC6_24",price:"8,255",l2:"6 vCPU, 24 GB RAM",l3:"300 GB SSD",l4:"200 GB",link:""},
    {h3:"WC8_24",price:"9,648",l2:"8 vCPU, 24 GB RAM",l3:"400 GB SSD",l4:"300 GB",link:""},
    {h3:"WC8_32",price:"10,346",l2:"8 vCPU, 32 GB RAM",l3:"400 GB SSD",l4:"300 GB",link:""},
    {h3:"WC12_48",price:"14,478",l2:"12 vCPU, 48 GB RAM",l3:"500 GB SSD",l4:"400 GB",link:""},
    {h3:"WC12_64",price:"16,252",l2:"12 vCPU, 64 GB RAM",l3:"500 GB SSD",l4:"400 GB",link:""},
    {h3:"WC16_64",price:"21,696",l2:"16 vCPU, 64 GB RAM",l3:"800 GB SSD",l4:"500 GB",link:""},
    {h3:"WC16_128",price:"30,745",l2:"16 vCPU, 128 GB RAM",l3:"1200 GB SSD",l4:"1024 GB",link:""},
    {h3:"WC24_128",price:"33,854",l2:"24 vCPU, 128 GB RAM",l3:"1024 GB SSD",l4:"700 GB",link:""},
    {h3:"WC24_256",price:"50,662",l2:"24 vCPU, 256 GB RAM",l3:"1500 GB SSD",l4:"1500 GB",link:""},
    {h3:"WC32_256",price:"56,965",l2:"32 vCPU, 256 GB RAM",l3:"1100 GB SSD",l4:"1000 GB",link:""},
    {h3:"WC32_512",price:"82,118",l2:"32 vCPU, 512 GB RAM",l3:"2048 GB SSD",l4:"2048 GB",link:""},
]

export const generalPurpose=[
    {h3:"GP1_2",price:"1,103",l2:"1 vCPU, 2 GB RAM",l3:"50 GB SSD",l4:"150 IOPs",l1:"100 GB",link:""},
    {h3:"GP2_4",price:"1,704",l2:"2 vCPU, 4 GB RAM",l3:"80 GB SSD",l4:"240 IOPs",l1:"100 GB",link:""},
    {h3:"GP4_8",price:"2,666",l2:"4 vCPU, 8 GB RAM",l3:"100 GB SSD",l4:"300 IOPs",l1:"100 GB",link:""},
    {h3:"GP4_12",price:"3,015",l2:"4 vCPU, 12 GB RAM",l3:"100 GB SSD",l4:"300 IOPs",l1:"100 GB",link:""},
    {h3:"GP4_16",price:"3,364",l2:"4 vCPU, 16 GB RAM",l3:"100 GB SSD",l4:"300 IOPs",l1:"100 GB",link:""},
    {h3:"GP6_12",price:"5,004",l2:"6 vCPU, 12 GB RAM",l3:"300 GB SSD",l4:"900 IOPs",l1:"200 GB",link:""},
    {h3:"GP6_18",price:"5,532",l2:"6 vCPU, 18 GB RAM",l3:"300 GB SSD",l4:"900 IOPs",l1:"200 GB",link:""},
    {h3:"GP6_24",price:"6,055",l2:"6 vCPU, 24 GB RAM",l3:"300 GB SSD",l4:"900 IOPs",l1:"200 GB",link:""},
    {h3:"GP8_16",price:"6,751",l2:"8 vCPU, 16 GB RAM",l3:"400 GB SSD",l4:"1200 IOPs",l1:"300 GB",link:""},
    {h3:"GP8_24",price:"7,448",l2:"8 vCPU, 24 GB RAM",l3:"400 GB SSD",l4:"1200 IOPs",l1:"300 GB",link:""},
    {h3:"GP8_32",price:"8,146",l2:"8 vCPU, 32 GB RAM",l3:"400 GB SSD",l4:"1200 IOPs",l1:"300 GB",link:""},
    {h3:"GP12_36",price:"10,382",l2:"12 vCPU, 36 GB RAM",l3:"500 GB SSD",l4:"1500 IOPs",l1:"400 GB",link:""},
    {h3:"GP12_48",price:"11,428",l2:"12 vCPU, 48 GB RAM",l3:"500 GB SSD",l4:"1500 IOPs",l1:"400 GB",link:""},
    {h3:"GP12_64",price:"12,822",l2:"12 vCPU, 64 GB RAM",l3:"500 GB SSD",l4:"1500 IOPs",l1:"400 GB",link:""},
]

export const computeOptimized=[
    {h3:"CO4_8",price:"4,038",l2:"4 vCPU, 8 GB RAM",l3:"200 GB SSD",l4:"6000 IOPs",l1:"200 GB",link:""},
    {h3:"CO4_16",price:"4,735",l2:"4 vCPU, 16 GB RAM",l3:"200 GB SSD",l4:"600 IOPs",l1:"200 GB",link:""},
    {h3:"CO6_12",price:"6,016",l2:"6 vCPU, 12 GB RAM",l3:"300 GB SSD",l4:"900 IOPs",l1:"300 GB",link:""},
    {h3:"CO8_8",price:"7,297",l2:"8 vCPU, 8 GB RAM",l3:"400 GB SSD",l4:"1200 IOPs",l1:"400 GB",link:""},
    {h3:"CO8_16",price:"7,944",l2:"8 vCPU, 16 GB RAM",l3:"400 GB SSD",l4:"1200 IOPs",l1:"400 GB",link:""},
    {h3:"CO8_32",price:"9,389",l2:"8 vCPU, 32 GB RAM",l3:"400 GB SSD",l4:"1200 IOPs",l1:"400 GB",link:""},
    {h3:"CO12_24",price:"11,651",l2:"12 vCPU, 24 GB RAM",l3:"600 GB SSD",l4:"1800 IOPs",l1:"500 GB",link:""},
    {h3:"CO12_48",price:"13,742",l2:"12 vCPU, 48 GB RAM",l3:"600 GB SSD",l4:"1800 IOPs",l1:"500 GB",link:""},
    {h3:"CO16_32",price:"15,007",l2:"16 vCPU, 32 GB RAM",l3:"800 GB SSD",l4:"2400 IOPs",l1:"500 GB",link:""},
    {h3:"CO16_64",price:"17,796",l2:"16 vCPU, 64 GB RAM",l3:"800 GB SSD",l4:"2400 IOPs",l1:"500 GB",link:""},
    {h3:"CO16_96",price:"20,585",l2:"16 vCPU, 96 GB RAM",l3:"800 GB SSD",l4:"2400 IOPs",l1:"500 GB",link:""},
    {h3:"CO24_48",price:"21,282",l2:"24 vCPU, 48 GB RAM",l3:"1024 GB SSD",l4:"3072 IOPs",l1:"700 GB",link:""},
    {h3:"CO24_96",price:"25,465",l2:"24 vCPU, 96 GB RAM",l3:"1024 GB SSD",l4:"3072 IOPs",l1:"700 GB",link:""},
    {h3:"CO32_64",price:"26,933",l2:"32 vCPU, 64 GB RAM",l3:"1100 GB SSD",l4:"3300 IOPs",l1:"1000 GB",link:""},
    {h3:"CO24_128",price:"28,254",l2:"24 vCPU, 128 GB RAM",l3:"1024 GB SSD",l4:"3072 IOPs",l1:"700 GB",link:""},
    {h3:"CO32_128",price:"32,510",l2:"32 vCPU, 128 GB RAM",l3:"1100 GB SSD",l4:"3300 IOPs",l1:"1000 GB",link:""},
    {h3:"CO32_256",price:"43,665",l2:"32 vCPU, 256 GB RAM",l3:"1100 GB SSD",l4:"3300 IOPs",l1:"1000 GB",link:""},
]

export const memoryOptimized=[
    {h3:"MO2_6",price:"2,598",l2:"2 vCPU, 6 GB RAM",l3:"150 GB SSD",l4:"450 IOPs",l1:"200 GB",link:"",P:"500"},
    {h3:"MO4_32",price:"7,758",l2:"4 vCPU, 32 GB RAM",l3:"400 GB SSD",l4:"1200 IOPs",l1:"500 GB",link:"",P:"500"},
    {h3:"MO6_32",price:"9,452",l2:"6 vCPU, 32 GB RAM",l3:"600 GB SSD",l4:"1800 IOPs",l1:"500 GB",link:"",P:"500"},
    {h3:"MO4_64",price:"10,547",l2:"4 vCPU, 64 GB RAM",l3:"400 GB SSD",l4:"1200 IOPs",l1:"500 GB",link:"",P:"500"},
    {h3:"MO8_48",price:"12,540",l2:"8 vCPU, 48 GB RAM",l3:"700 GB SSD",l4:"2100 IOPs",l1:"700 GB",link:"",P:"1000"},
    {h3:"MO8_64",price:"14,834",l2:"8 vCPU, 64 GB RAM",l3:"800 GB SSD",l4:"2400 IOPs",l1:"800 GB",link:"",P:"1000"},
    {h3:"MO10_64",price:"17,707",l2:"10 vCPU, 64 GB RAM",l3:"900 GB SSD",l4:"2700 IOPs",l1:"1000 GB",link:"",P:"500"},
    {h3:"MO10_96",price:"21,096",l2:"10 vCPU, 96 GB RAM",l3:"1000 GB SSD",l4:"3000 IOPs",l1:"1000 GB",link:"",P:"1000"},
    {h3:"MO12_96",price:"22,497",l2:"12 vCPU, 96 GB RAM",l3:"1100 GB SSD",l4:"3300 IOPs",l1:"1024 GB",link:"",P:"1000"},
    {h3:"MO16_128",price:"27,345",l2:"16 vCPU, 128 GB RAM",l3:"1200 GB SSD",l4:"3600 IOPs",l1:"1024 GB",link:"",P:"1000"},
    {h3:"MO24_256",price:"44,646",l2:"24 vCPU, 256 GB RAM",l3:"1500 GB SSD",l4:"4500 IOPs",l1:"1500 GB",link:"",P:"1000"},
    {h3:"MO32_512",price:"77,818",l2:"32 vCPU, 512 GB RAM",l3:"2048 GB SSD",l4:"6144 IOPs",l1:"2048 GB",link:"",P:"1000"},
]

export const course=[
    {imgUrl:`${azure104}`,price:"60,000",name:"Azure-104",p:"Adaptable Profession. You can choose from various job paths if you have a Microsoft Azure certification through AZ 104 exam."}
]