import React from 'react'
import './home.css'
import wr from '../../images/wr.gif'

const Window = () => {
  return (
    <>
    <div className="window">
      <div className="left-window">
        <h1>Stop the search for your domain name here</h1>
        <p>To find available domain name, use the search bar to stop the journey of finding the perfect domain</p>
        <div className="search">
          <input type="search" name="" id="" />
          <i className='fa fa-search'></i>
          <i className='fa fa-refresh'></i>

        </div>
      </div>
      <div className="right-window">
          <img src={wr} alt="" className='wr'/>
      </div>
    </div>
    </>
  )
}

export default Window