import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/footer/Footer';
import Home from './components/pages/Home/Home';
import Hosting from './components/pages/hosting/Hosting';
import SslCertificate from './components/pages/sslCertificate/SslCertificate'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import CPanelLinuxCloud from './components/pages/products/CPanelLinuxCloud';
import WindowsCloud from './components/pages/products/WindowsCloud';
import CPanelGeneralPurpose from './components/pages/products/CPanelGeneralPurpose'
import CPanelComputeOptimized from './components/pages/products/CPanelComputeOptimized';
import CPanelMemoryOptimized from './components/pages/products/CPanelMemoryOptimized'
import Azure from './components/pages/training/Azure';
import ProductView from './components/pages/ProductView/ProductView';
import Checkout from './components/pages/checkout/Checkout';
function App() {
  return (
<>
<Router>
    <Navbar/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path='/sslcertificate' element={<SslCertificate/>}/>
      <Route path='/hosting' element={<Hosting/>}/>
      <Route path='/cpanel-linuxcloud' element={<CPanelLinuxCloud/>}/>
      <Route path='/windows-cloud' element={<WindowsCloud/>}/>
      <Route path='/cpanel-linux-generalpurpose' element={<CPanelGeneralPurpose/>}/>
      <Route path="/cpanel-linux-computeoptimized" element={<CPanelComputeOptimized/>}/>
      <Route path='/cpanel-linux-memoryoptimized' element={<CPanelMemoryOptimized/>}/>
      <Route path='/training' element={<Azure/>}/>
      <Route path="/pview" element={<ProductView/>}/>
      <Route path="/checkout" element={<Checkout/>}/>
      </Routes>
    <Footer/>
    
    </Router>
</>
  );
}

export default App;
