import React from 'react'
import { windowCloudData } from '../../data/data'
import './product.css'
import Shopping from '../../shoppingCart/shopping'
import {Link} from 'react-router-dom'
const WindowsCloud = () => {
  return (
    <>
    <Shopping path=" > Windows Cloud"/>
    <div className="product">
        <h1>Windows Cloud</h1>
        <p>The best way to run Windows Compute on Cloud is leveraging Hybrid Benefits. Hybrid benefit is a licensing benefit that significantly reduce the cost of infrastructure workload in the cloud using your premises software licenses with SA. Windows VMs are economical, multi-purpose with balenced CPU to memory ratio that lets you attach a significant SSD or HDD storage as per your requirement.</p>
        <div className="product-cards">
            {
                windowCloudData.map((elem)=>{
                    return <div className="productcard" key={elem.price}>
                        <h3>{elem.h3}</h3>
                        <h1>&#x20B9; {elem.price}<span>/mo</span></h1>
                        <ul>
                            <li><i className="fa fa-check-circle"></i>Compute: <span>{elem.l2}</span></li>
                            <li><i className="fa fa-check-circle"></i>Disk Space: <span>{elem.l3}</span></li>
                            <li><i className="fa fa-check-circle"></i>Data Transfer: <span>{elem.l4}</span></li>
                            <li><i className="fa fa-check-circle"></i>Public Static IP: <span>1</span></li>
                            <li><i className="fa fa-check-circle"></i>OS Type: <span>Windows 2016/19</span></li>
                            <li><i className="fa fa-check-circle"></i>Acronis Backup : 100 GB Free</li>
                        </ul>
                        <Link to="#">Order Now</Link>
                    </div>
                })
            }
        </div>
    </div>
    </>
  )
}

export default WindowsCloud