import React from 'react'
import './footer.css'
import s1 from '../images/mcafee-logo.png'
import s2 from '../images/accredited-logo.png'
import s3 from '../images/avast-logo.png'
import s4 from '../images/avg_logo.png'
import f1 from '../images/fi1.png'
import f2 from '../images/fi2.png'
import f3 from '../images/fi3.png'
import f4 from '../images/fi4.png'
import f5 from '../images/fi5.png'
import {Link} from 'react-router-dom'
const Footer = () => {
  return (
    <>
    <div className="footer">
      <div className="f-content">
        <div className="f1">
          <h3>SECURED BY</h3>
          <img src={s1} alt="" />
          <img src={s2} alt="" />
          <img src={s3} alt="" />
          <img src={s4} alt="" />
        </div>
        <div className="f2">
          <h3>PRODUCTS</h3>
          <p><Link to="/cpanel-linuxcloud">cPanel Linux Cloud</Link></p>
          <p><Link to="/windows-cloud">Windows Cloud</Link></p>
          <p><Link to="/cpanel-linux-generalpurpose">Linux Cloud : General Purpose</Link></p>
          <p><Link to="/cpanel-linux-computeoptimized">Linux Cloud : Compute Optimized</Link></p>
          <p><Link to="/cpanel-linux-memoryoptimized">Linux Cloud : Memory Optimized</Link></p>
          <p><Link to="/hosting">Dedicated Server</Link></p>
          <p><Link to="#">Domain Names</Link></p>
        </div>
        <div className="f3">
          <h3>CONTACT US</h3>
          <p>NETSEEMS VENTURES PRIVATE LIMITED</p>
          <p>A19, Om Bungalow, Sai Jyot Park, Rahatani, <br />Pune, Maharashtra - 411017</p>
          <p>Mob: +91 8421056291</p>
          <p>Email Id : sales@netseems.com</p>
        </div>
      </div>
      <div className="social">
        <Link to="#"><i className="fa fa-facebook-f"></i></Link>
        <Link to="#"><i className="fa fa-instagram"></i></Link>
        <Link to="#"><i className="fa fa-linkedin"></i></Link>
        <Link to="#"><i className="fa fa-youtube-play"></i></Link>
      </div>
    </div>
    <div className="foot">
      <p>Copyright © 2023 <span>NetSeems Ventures Pvt. Ltd.</span> All Rights Reserved.</p>
      <div>
        <img src={f1} alt="" />
        <img src={f2} alt="" />
        <img src={f3} alt="" />
        <img src={f4} alt="" />
        <img src={f5} alt="" />
      </div>
    </div>
    
    </>
  )
}

export default Footer