import React from 'react'
import { memoryOptimized } from '../../data/data'
import './product.css'
import Shopping from '../../shoppingCart/shopping'
import {Link} from 'react-router-dom'
const CPanelMemoryOptimized = () => {
  return (
    <>
    <Shopping path=" > Linux Cloud : Memory Optimized"/>
    <div className="product">
        <h1>Linux Cloud : Memory Optimized</h1>
        <p>High memory compute are designed for better performance of databases such as MySQL, No SQL, ERP worklaods and data warehousing solution includes two to three time of memory and 4 times of Solid State Drive (SSD) than the general purpose compute.</p>
        <div className="product-cards">
            {
                memoryOptimized.map((elem)=>{
                    return <div className="productcard" key={elem.price}>
                        <h3>{elem.h3}</h3>
                        <p>&#x20B9; {elem.P} Setup Fee</p>
                        <h1>&#x20B9; {elem.price}<span>/mo</span></h1>
                        <ul>
                            <li><i className="fa fa-check-circle"></i>Compute: <span>{elem.l2}</span></li>
                            <li><i className="fa fa-check-circle"></i>Disk Space: <span>{elem.l3}</span></li>
                            <li><i className="fa fa-check-circle"></i>IOPs: <span>{elem.l4}</span></li>
                            <li><i className="fa fa-check-circle"></i>Data Transfer: <span>{elem.l1}</span></li>
                            <li><i className="fa fa-check-circle"></i>Public Static IP: <span>1</span></li>
                            <li><i className="fa fa-check-circle"></i>vNIC: <span>Upto 10G</span></li>
                            <li><i className="fa fa-check-circle"></i>OS: <span>CentOS/Debian/Ubuntu</span></li>
                        </ul>
                        <Link to="#">Order Now</Link>
                    </div>
                })
            }
        </div>
    </div>
    </>
  )
}

export default CPanelMemoryOptimized 