import React from 'react'
import './productview.css'
import img from '../../images/az-104.jpg'
import Shopping from '../../shoppingCart/shopping'
import {Link} from 'react-router-dom'
const ProductView = () => {
  return (
    <>
    <Shopping/>
    <div className="productview">
      <div className="pvl">
          <img src={img} alt="" />
      </div>
      <div className="pvr">
        <h1 className="name">Azure-104</h1>
        <h2 className="price">&#x20B9; 70,800 <span>MOST POPULAR</span></h2>
        <div className="table">
          <table>
            <tbody>
              
              <tr>
                <th>Actual Price</th>
                <td>&#x20B9; 60,000 Payable</td>
              </tr>
              <tr>
                <th>GST @18%</th>
                <td>&#x20B9; 10,800</td>
              </tr>
              <tr>
                <th>Duration</th>
                <td>6 Months</td>
              </tr>
              <tr>
                <th>Certificate</th>
                <td>Yes</td>
              </tr>
              <tr>
                <th>Payment</th>
                <td>One Time Payment</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Link to="/checkout">Add to Cart</Link>
      </div>
    </div>
    
    </>
  )
}

export default ProductView