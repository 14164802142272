import React from 'react'
import {homeCards} from '../../../data/data'
import './homecards.css'
import {Link} from 'react-router-dom'
const HomeCards = () => {
  return (
    <div className="homecards">
      <h1>Best Cloud Computing Solution for your need</h1>
      <div className="home-cards">
        {
            homeCards.map((card)=>{
              return <div className="homecard" key={card.price}>
              <img src={card.imgUrl} alt=""/>
              <h3>{card.h3}</h3>
              <p>{card.p}</p>
              <p>Starting at : <span>&#x20B9; {card.price}/mo</span></p>
              <Link to="#">View Details</Link>
          </div>
            })
        }
      </div>
    </div>
  )
}

export default HomeCards