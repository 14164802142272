import React from 'react'
import './homeabout.css'
import {Link} from 'react-router-dom'
const HomeAbout = () => {
  return (
    <>
    <div className="homeabout">
      <h2>Would You Like Our Help For Cloud Consulting</h2>
      <p className='home-para'>Weither you are planning for migration from on-premise to cloud or looking for multi cloud deployment, don't hesitate to reach out to our multicloud Certified Architect. Request for Call/Email:+91 8421056291/sales@netseems.com</p>
      <p>Sales Chat: <span>MON-FRI 10AM-6PM IST </span>| Customer Support Chat: Every Day <span>5:30AM–9:30PM IST</span></p>
      <Link to="#" className='a1'>Chat With Us</Link>
      <Link to="#" className='a2'>Send Us an Email</Link>
    </div>
    </>
  )
}

export default HomeAbout