import React from 'react'
import { cpanelData } from '../../data/data'
import './product.css'
import Shopping from '../../shoppingCart/shopping'
import {Link} from 'react-router-dom'
const CPanelLinuxCloud = () => {
  return (
    <>
    <Shopping path=" > cPanel Linux Cloud"/>
    <div className="product">
        <h1>cPanel Linux Cloud</h1>
        <p>The Linux-based control panel is used to conveniently manage your web hosting. cPanel Linux cloud is Available in different optimization like Memory and compute. cPanel Linux cloud along with 100% of SSD storage and available free onboarding support. It is designed to provide a stable, secure, and high-performance execution.</p>
        <div className="product-cards">
            {
                cpanelData.map((elem)=>{
                    return <div className="productcard" key={elem.price}>
                        <h3>{elem.h3}</h3>
                        <h1>&#x20B9; {elem.price}<span>/mo</span></h1>
                        <ul>
                            <li><i className="fa fa-check-circle"></i>User Account: <span>{elem.l1}</span></li>
                            <li><i className="fa fa-check-circle"></i>CPU: <span>{elem.l2}</span></li>
                            <li><i className="fa fa-check-circle"></i>Disk Space: <span>{elem.l3}</span></li>
                            <li><i className="fa fa-check-circle"></i>Data Transfer: <span>{elem.l4}</span></li>
                            <li><i className="fa fa-check-circle"></i>OS: <span>CentOS/Debian/Ubuntu</span></li>
                            <li><i className="fa fa-check-circle"></i>cPanel & WHM Access</li>
                            <li><i className="fa fa-check-circle"></i>Free Onboarding Support</li>
                        </ul>
                        <Link to="#">Order Now</Link>

                        
                    </div>
                })
            }
        </div>
    </div>
    </>
  )
}

export default CPanelLinuxCloud