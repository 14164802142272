import React from 'react'
import { generalPurpose } from '../../data/data'
import './product.css'
import Shopping from '../../shoppingCart/shopping'
import {Link} from 'react-router-dom'
const CPanelGeneralPurpose = () => {
  return (
    <>
    <Shopping path=" > Linux Cloud : General Purpose"/>
    <div className="product">
        <h1>Linux Cloud : General Purpose</h1>
        <p>Low traffic web servers, applications, development, test servers, small and medium databases require a balance of compute, memory and networking resources .General purpose compute are best for this kind of workload, you can either add more compute or upgrade the configurations of the current compute at any time as your business grows.</p>
        <div className="product-cards">
            {
                generalPurpose.map((elem)=>{
                    return <div className="productcard" key={elem.price}>
                        <h3>{elem.h3}</h3>
                        <h1>&#x20B9; {elem.price}<span>/mo</span></h1>
                        <ul>
                            <li><i className="fa fa-check-circle"></i>CPU: <span>{elem.l2}</span></li>
                            <li><i className="fa fa-check-circle"></i>Disk Space: <span>{elem.l3}</span></li>
                            <li><i className="fa fa-check-circle"></i>IOPs: <span>{elem.l4}</span></li>
                            <li><i className="fa fa-check-circle"></i>Data Transfer: <span>{elem.l1}</span></li>
                            <li><i className="fa fa-check-circle"></i>Public Static IP: <span>1</span></li>
                            <li><i className="fa fa-check-circle"></i>vNIC: <span>Upto 10G</span></li>
                            <li><i className="fa fa-check-circle"></i>OS: <span>CentOS/Debian/Ubuntu</span></li>
                        </ul>
                        <Link to="#">Order Now</Link>
                    </div>
                })
            }
        </div>
    </div>
    </>
  )
}

export default CPanelGeneralPurpose 