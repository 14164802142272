import React from 'react'
import './training.css'
import Shopping from '../../shoppingCart/shopping'
import { course } from '../../data/data'
import {Link} from 'react-router-dom'
const Azure = () => {
  return (
    <>
    <Shopping path=" > Azure"/>
    <div className="azure">
        <h1>AZURE</h1>
        <div className="azure-cards">
          {
              course.map((elem)=>{
                return <div className="azurecard" key={elem.price}>
                  <img src={elem.imgUrl} alt="" />
                  <h2 className="name">{elem.name}</h2>
                  <p className="about">{elem.p}</p>
                  <Link to="/pview">Order Now</Link>
                </div>
              })
          }
        </div>
    </div>
    </>
  )
}

export default Azure