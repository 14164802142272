import React from 'react'
import center from '../../../images/data-center.png'
import './connect.css'
import {Link} from 'react-router-dom'
const Connect = () => {
  return (
    <>
    <div className="connect">
        <div className="c-left">
            <h2>Create Your Own Cloud Computing Platform Using Our Cloud Bucket</h2>
            <ul>
                <li><i className="fa fa-check-circle"></i>Fixed INR Billing</li>
                <li><i className="fa fa-check-circle"></i>One Click Upgrade/Downgrade</li>
                <li><i className="fa fa-check-circle"></i>Provision Customised VM Within Minutes</li>
                <li><i className="fa fa-check-circle"></i>Secured Access To Panel</li>
                <li><i className="fa fa-check-circle"></i>Flexible, Easy To Use Control Panel</li>
                <li className='last'><i className="fa fa-check-circle"></i>Full control over cloud bucket</li>
            </ul>
            <Link to="#">Connect For More Information</Link>
        </div>
        <div className="c-right">
            <img src={center} alt="" />
        </div>
    </div>
    </>
  )
}

export default Connect