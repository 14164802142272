import React from 'react'
import Shopping from '../../shoppingCart/shopping'
const Checkout = () => {
  return (
    <>
    <Shopping/>
    <div className="checkout">
        <h1>CHECKOUT</h1>
    </div>
    </>
  )
}

export default Checkout