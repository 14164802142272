import React from 'react'
import './sslcertificate.css'
import Shopping from '../../shoppingCart/shopping'
import { sslData } from '../../data/data'
import {Link} from 'react-router-dom'
const SslCertificate = () => {
  return (
    <>
    <Shopping path=" > SSL Certificate"/>
    <div className="ssl-cards">
        {
            sslData.map((elem)=>{
                return <div className="sslcard" key={elem.price}>
                <h3>{elem.h3}</h3>
                <p>{elem.p}</p>
                <h1 className="price">&#x20B9; {elem.price}<span>/mo</span></h1>
                <ul>
                    <li><i className="fa fa-check-circle"></i>Domain Validation</li>
                    <li><i className="fa fa-check-circle"></i>1 Sub-domain</li>
                    <li><i className="fa fa-check-circle"></i>SHA2 & ECC 128/256 bit Encryption</li>
                    <li><i className="fa fa-check-circle"></i>Trust Logo Supported</li>
                    <li><i className="fa fa-check-circle"></i>Issued within 2 Days</li>
                    <li><i className="fa fa-check-circle"></i>Free Reissuance</li>
                    <li><i className="fa fa-check-circle"></i>${elem.warran} Warranty</li>
                    <li><i className="fa fa-check-circle"></i>40 days Money Back</li>
                    
                </ul>
                <Link to="#">Order Now</Link>
            </div>
            })
        }
        
       
       
    </div>
    </>
  )
}

export default SslCertificate