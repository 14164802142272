import React from 'react'
import { computeOptimized } from '../../data/data'
import './product.css'
import Shopping from '../../shoppingCart/shopping'
import {Link} from 'react-router-dom'
const CPanelComputeOptimized = () => {
  return (
    <>
    <Shopping path=" > Linux Cloud : Compute Optimized"/>
    <div className="product">
        <h1>Linux Cloud : Compute Optimized</h1>
        <p>High performance web servers, media transcoding, machine learning inference, high performance databases require high CPU to memory ratio. Compute optimized VMs are ideal for compute bound workload and you can either add more compute or upgrade the configurations of the current compute at any time as your business grows.</p>
        <div className="product-cards">
            {
                computeOptimized.map((elem)=>{
                    return <div className="productcard" key={elem.price}>
                        <h3>{elem.h3}</h3>
                        <h1>&#x20B9; {elem.price}<span>/mo</span></h1>
                        <ul>
                            <li><i className="fa fa-check-circle"></i>Compute: <span>{elem.l2}</span></li>
                            <li><i className="fa fa-check-circle"></i>Disk Space: <span>{elem.l3}</span></li>
                            <li><i className="fa fa-check-circle"></i>IOPs: <span>{elem.l4}</span></li>
                            <li><i className="fa fa-check-circle"></i>Data Transfer: <span>{elem.l1}</span></li>
                            <li><i className="fa fa-check-circle"></i>Public Static IP: <span>1</span></li>
                            <li><i className="fa fa-check-circle"></i>vNIC: <span>Upto 10G</span></li>
                            <li><i className="fa fa-check-circle"></i>OS: <span>CentOS/Debian/Ubuntu</span></li>
                        </ul>
                        <Link to="#">Order Now</Link>
                    </div>
                })
            }
        </div>
    </div>
    </>
  )
}

export default CPanelComputeOptimized 