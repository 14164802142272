import React from 'react'
import Window from './Window'
import Testimonial from './testiamonials/Testimonial'
import './home.css'
import Connect from './connect/Connect'
import HomeAbout from './homeabout/HomeAbout'
import HomeCards from './homecards/HomeCards'
const Home = () => {
  return (
    <>
    <div className="home">
    <Window/>
    <HomeCards/>
    <HomeAbout/>
    <Connect/>
    <Testimonial/>
    </div>
    
    </>
  )
}

export default Home